import React, { useState } from 'react';
import { Modal, Input } from 'antd';

function ItemModal(props) {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  return (
    <Modal
      title={`Add an item (Row ${props.rowIndex + 1})`}
      visible={props.visible}
      onOk={() => props.onOk && props.onOk(parseFloat(width), parseFloat(height))}
      onCancel={() => props.onCancel && props.onCancel()}
    >
      <Input.Group size="large" style={{ margin: 'auto' }}>
        <Input
          style={{
            width: 200,
            textAlign: 'center',
            borderRight: 0,
          }}
          placeholder="Width (mm)"
          autoFocus
          value={width}
          onChange={e => setWidth(e.target.value)}
        />
        <Input
          className="site-input-split"
          style={{
            width: 30,
            borderLeft: 0,
            borderRight: 0,
            pointerEvents: 'none',
          }}
          placeholder="x"
          disabled
        />
        <Input
          className="site-input-right"
          style={{
            width: 200,
            textAlign: 'center',
            borderLeft: 0,
          }}
          placeholder="Height (mm)"
          value={height}
          onChange={e => setHeight(e.target.value)}
        />
      </Input.Group>
    </Modal>
  );
}

export default ItemModal;
