import React, { useState } from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

function ExportModal(props) {
  return (
    <Modal
      title={`Export Layout`}
      visible={props.visible}
      footer={false}
      onCancel={() => props.onCancel()}
    >
      <div>Copy the text and save it to a file (or you can simply share the current URL!)</div>
      <TextArea
        rows={4}
        value={props.content}
        onFocus={e => {
          e.target.select();
        }}
        autoFocus
      />
    </Modal>
  );
}

export default ExportModal;
