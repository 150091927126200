import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Checkbox, Button, Modal, message } from 'antd';
import { PlusOutlined, DeleteOutlined, CopyOutlined, LeftOutlined, RightOutlined, UpOutlined, DownOutlined, SaveOutlined, FolderOpenOutlined } from '@ant-design/icons';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ItemModal from './ItemModal';
import ExportModal from './ExportModal';
import ImportModal from './ImportModal';

const { Option } = Select;

function App() {
  const [size, setSize] = useState(840);
  const [rows, setRows] = useState([{cols: []}]);
  const [hoveringItem, setHoveringItem] = useState(null);

  const [itemModalProps, setItemModalProps] = useState({
    key: Math.random(),
    visible: false,
    rowIndex: null,
  });

  const [exportModalProps, setExportModalProps] = useState({
    visible: false,
  });

  const [importModalProps, setImportModalProps] = useState({
    key: Math.random(),
    visible: false,
  });

  const getDecodedData = (str) => {
    return JSON.parse(new Buffer(str, 'base64').toString());
  };

  const getEncodedData = () => {
    return new Buffer(JSON.stringify({
      size,
      rows,
    })).toString('base64');
  };

  useEffect(() => {
    if (window.location.hash) {
      try {
        const data = getDecodedData(window.location.hash);
        if (data.rows && data.size) {
          setRows(data.rows);
          setSize(data.size);
        }
      } catch (err) {}
    }
  }, []);

  useDeepCompareEffect(() => {
    window.location.hash = getEncodedData();
  }, [rows, size]);

  // 35, 65, 90, 160
  const getPusherSize = (width) => {
    if (width < 65) {
      return 'N';
    } else if (width < 90) {
      return 'S';
    } else if (width < 160) {
      return 'M';
    } else {
      return 'L';
    }
  };

  const moveSlot = (i, j, offsetX, offsetY) => {
    let rowsNew = null;

    if (offsetX !== 0) {
      rowsNew = rows.map((row, i2) => {
        return {
          ...row,
          cols: i === i2
            ? (
              offsetX > 0
              ? [...row.cols.slice(0, j), row.cols[j + 2], row.cols[j + 3], row.cols[j], row.cols[j + 1], ...row.cols.slice(j + 4)]
              : [...row.cols.slice(0, j - 2), row.cols[j], row.cols[j + 1], row.cols[j - 2], row.cols[j - 1], ...row.cols.slice(j + 2)]
            )
            : row.cols,
        };
      })
    } else if (offsetY !== 0) {
      if (offsetY < 0) {
        rowsNew = rows.map((row, i2) => {
          return {
            ...row,
            cols: i2 === i - 1
              ? [...row.cols, rows[i].cols[j], rows[i].cols[j + 1]]
              : i2 === i ? [...row.cols.slice(0, j), ...row.cols.slice(j + 2)] : row.cols,
          };
        });
      } else {
        if (i === rows.length - 1) {
          rowsNew = [...rows.map((row, i2) => {
            return {
              ...row,
              cols: i2 === i
                ? [...row.cols.slice(0, j), ...row.cols.slice(j + 2)]
                : row.cols,
            };
          }), {
            cols: [
              rows[i].cols[j],
              rows[i].cols[j + 1],
            ],
          }];
        } else {
          rowsNew = rows.map((row, i2) => {
            return {
              ...row,
              cols: i2 === i + 1
                ? [...row.cols, rows[i].cols[j], rows[i].cols[j + 1]]
                : i2 === i ? [...row.cols.slice(0, j), ...row.cols.slice(j + 2)] : row.cols,
            };
          });
        }
      }
    }

    if (rowsNew) {
      setRows(rowsNew);
    }
  };

  const removeSlot = (i, j) => {
    setRows(rows.map((row, i2) => {
      return {
        ...row,
        cols: i === i2
          ? row.cols.filter((col, j2) => j2 !== j && j2 !== j + 1)
          : row.cols,
      };
    }));
  };

  const cloneSlot = (i, j) => {
    setRows(rows.map((row, i2) => {
      return {
        ...row,
        cols: i === i2
          ? [...row.cols, row.cols[j], row.cols[j + 1]]
          : row.cols,
      };
    }));
  };

  const renderSlots = () => {
    let bottom = 0;
    return (
      <div
        id="y-slot-container"
        className={hoveringItem ? 'hovering' : ''}
      >
        {rows.map((row, i) => {
          let minHeight = 90;
          if (i > 0) {
            for (let col of rows[i - 1].cols) {
              if (col.type === 'item') {
                minHeight = Math.max(minHeight, Math.ceil(col.height / 15) * 15);
              }
            }
            bottom += minHeight + 30;
          }

          const hasBlock = row.cols.length > 0 && row.cols[0].type === 'wall';
          let blockWidth = 30;

          let right = 0;

          if (hasBlock) {
            if (row.cols[0].type === 'wall' && row.cols.length >= 3) {
              const secondItem = row.cols[1];
              const secondItemWidth = Math.ceil(secondItem.width / 15) * 15;

              if (secondItemWidth + 60 < 150) {
                right = 120 - secondItemWidth;
                blockWidth = 120 - secondItemWidth;
              } else {
                right = 30;
              }
            } else {
              right = 30;
            }
          }

          return (
            <div
              key={i}
              style={{ bottom }}
            >
              {hasBlock && (
                <div className="has-block" style={{ width: blockWidth }}></div>
              )}

              {row.cols.map((col, j) => {
                let currRight = right;

                if (col.type === 'item') {
                  right += Math.ceil(col.width / 15) * 15;
                } else if (col.type === 'wall') {
                  right += 30;
                }

                const slotClasses = ['slot'];

                // wall
                if (col.type === 'wall') {
                  slotClasses.push('wall');
                  if (currRight < 150) {
                    slotClasses.push('error');
                  }

                  if (j > 0 && hoveringItem && hoveringItem[0] === i && hoveringItem[1] === j - 1) {
                    slotClasses.push('hovering');
                  }

                  return (
                    <div
                      key={j}
                      className={slotClasses.join(' ')}
                      style={{ right: currRight }}
                    >
                      {(currRight - 30) / 15 + 1}
                    </div>
                  );
                // item
                } else {
                  slotClasses.push('item');
                  if (hoveringItem && hoveringItem[0] === i && hoveringItem[1] === j) {
                    slotClasses.push('hovering');
                  }

                  return (
                    <div
                      key={j}
                      className={slotClasses.join(' ')}
                      style={{ width: col.width, height: col.height, right: currRight }}
                      onMouseEnter={(e) => setHoveringItem([i, j])}
                      onMouseLeave={(e) => setHoveringItem(null)}
                    >
                      <div className="dimension">{col.width}<br />x<br />{col.height}</div>
                      <div className="pusher">{getPusherSize(col.width)}</div>
                      <div className="action">
                        <div>
                          <Button
                            type="primary"
                            icon={<UpOutlined />}
                            onClick={() => {
                              moveSlot(i, j, 0, 1);
                              setHoveringItem(false);
                            }}
                          />
                        </div>
                        <div>
                          <Button
                            type="primary"
                            icon={<LeftOutlined />}
                            onClick={() => {
                              moveSlot(i, j, 1, 0);
                              setHoveringItem(false);
                            }}
                            disabled={j === row.cols.length - 2}
                          />
                          <Button
                            type="primary"
                            icon={<CopyOutlined />}
                            onClick={() => {
                              cloneSlot(i, j);
                              setHoveringItem(false);
                            }}
                          />
                          <Button
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              removeSlot(i, j);
                              setHoveringItem(false);
                            }}
                          />
                          <Button
                            type="primary"
                            icon={<RightOutlined />}
                            onClick={() => {
                              moveSlot(i, j, -1, 0);
                              setHoveringItem(false);
                            }}
                            disabled={j < 2}
                          />
                        </div>
                        <div>
                          <Button
                            type="primary"
                            icon={<DownOutlined />}
                            onClick={() => {
                              moveSlot(i, j, 0, -1);
                              setHoveringItem(false);
                            }}
                            disabled={i === 0}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="action">
                <div>
                  <Button type="primary" size="small" shape="circle" icon={<PlusOutlined />} onClick={() => {
                    const itemModalPropsNew = {
                      key: Math.random(),
                      visible: true,
                      rowIndex: i,
                      onOk: (width, height) => {
                        if (width > 0 && height > 0) {
                          row.cols.push({
                            type: 'item',
                            width,
                            height,
                          }, {
                            type: 'wall',
                          });

                          setItemModalProps({
                            ...itemModalPropsNew,
                            visible: false,
                          });
                        }
                      },
                      onCancel: (size) => {
                        setItemModalProps({
                          ...itemModalPropsNew,
                          visible: false,
                        });
                      },
                    };

                    setItemModalProps(itemModalPropsNew);
                  }} />
                  <Button type="primary" size="small" shape="circle" icon={<UpOutlined />} onClick={() => {
                    if (i === rows.length - 1) {
                      setRows([...rows.slice(0, i), {
                        cols: [],
                      }, row]);
                    } else {
                      setRows([...rows.slice(0, i), rows[i + 1], rows[i], ...rows.slice(i + 2)]);
                    }
                  }} />
                  <Button type="primary" size="small" shape="circle" icon={<DownOutlined />} onClick={() => {
                    setRows([...rows.slice(0, i - 1), rows[i], rows[i - 1], ...rows.slice(i + 1)]);
                  }} disabled={i === 0} />
                  <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} onClick={() => {
                    setRows([...rows.slice(0, i + 1), {
                      cols: row.cols.slice(),
                    }, ...rows.slice(i + 1)])
                  }} />
                  <Button type="danger" size="small" shape="circle" icon={<DeleteOutlined />} onClick={() => {
                    setRows([...rows.slice(0, i), ...rows.slice(i + 1)])
                  }} />
                </div>

                <div>
                  <div className="label">
                    <Checkbox checked={hasBlock} onChange={e => setRows(rows.map((rowTmp, k) => {
                      return i === k ? {...rowTmp, cols: (
                        rowTmp.cols[0] && rowTmp.cols[0].type === 'wall' ? rowTmp.cols.slice(1) : [{
                          type: 'wall',
                        }, ...rowTmp.cols]
                      )} : rowTmp;
                    }))} />
                    <span>{bottom / 15 + 1}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const layoutWidth = {
    1080: 790,
    840: 580,
    700: 430,
  }[size];

  return (
    <div>
      <Form id="nav">
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Machine Size">
              <Select value={size} onChange={val => setSize(val)}>
                <Option value={1080}>1080 (L)</Option>
                <Option value={840}>840 (M)</Option>
                <Option value={700}>700 (S)</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Layer No.">
              <Select value={rows.length} onChange={val => {
                const rowsNew = [...rows];
                for (let i = rows.length; i < val; ++i) {
                  rowsNew.push({
                    cols: [],
                  });
                }
                setRows(rowsNew.slice(0, val));
              }}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(val => (
                  <Option value={val} key={val}>{val}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => {
                setExportModalProps({
                  ...exportModalProps,
                  visible: true,
                });
              }}
            >
              Save
            </Button>
            <Button
              type="primary"
              icon={<FolderOpenOutlined />}
              onClick={() => {
                setImportModalProps({
                  ...importModalProps,
                  key: Math.random(),
                  visible: true,
                });
              }}
            >
              Load
            </Button>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure to clear the current layout?',
                  okType: 'danger',
                  onOk: () => {
                    setRows([]);
                  },
                });
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
      </Form>

      <div id="layout" style={{ width: layoutWidth }}>
        {renderSlots()}
      </div>

      <ItemModal {...itemModalProps} />
      <ExportModal
        {...exportModalProps}
        content={getEncodedData()}
        onCancel={() => {
          setExportModalProps({
            ...exportModalProps,
            visible: false,
          });
        }}
      />
      <ImportModal
        {...importModalProps}
        onOk={(content) => {
          setImportModalProps({
            ...importModalProps,
            visible: false,
          });

          try {
            const data = getDecodedData(content.trim());
            if (!data.rows || !data.size) {
              throw null;
            }
            setRows(data.rows);
            setSize(data.size);
            message.success('Import successfully!');
          } catch (err) {
            message.error('Import failed! Please check again');
          }
        }}
        onCancel={() => {
          setImportModalProps({
            ...importModalProps,
            visible: false,
          });
        }}
      />
    </div>
  );
}

export default App;
