import React, { useState } from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

function ImportModal(props) {
  const [content, setContent] = useState('');

  return (
    <Modal
      title={`Import Layout`}
      visible={props.visible}
      onOk={() => props.onOk(content)}
      onCancel={() => props.onCancel()}
    >
      <div>Paste the text below and submit to load a layout</div>
      <TextArea
        rows={4}
        value={content}
        onChange={e => setContent(e.target.value)}
        autoFocus
      />
    </Modal>
  );
}

export default ImportModal;
